// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-ceramics-js": () => import("./../../../src/pages/ceramics.js" /* webpackChunkName: "component---src-pages-ceramics-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-installations-js": () => import("./../../../src/pages/installations.js" /* webpackChunkName: "component---src-pages-installations-js" */),
  "component---src-pages-performances-js": () => import("./../../../src/pages/performances.js" /* webpackChunkName: "component---src-pages-performances-js" */),
  "component---src-pages-project-1-js": () => import("./../../../src/pages/project1.js" /* webpackChunkName: "component---src-pages-project-1-js" */),
  "component---src-pages-project-2-js": () => import("./../../../src/pages/project2.js" /* webpackChunkName: "component---src-pages-project-2-js" */),
  "component---src-pages-project-3-js": () => import("./../../../src/pages/project3.js" /* webpackChunkName: "component---src-pages-project-3-js" */),
  "component---src-pages-sculptures-js": () => import("./../../../src/pages/sculptures.js" /* webpackChunkName: "component---src-pages-sculptures-js" */),
  "component---src-templates-performance-js": () => import("./../../../src/templates/performance.js" /* webpackChunkName: "component---src-templates-performance-js" */),
  "component---src-templates-piece-js": () => import("./../../../src/templates/piece.js" /* webpackChunkName: "component---src-templates-piece-js" */)
}

